// .feedback-container {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin: 20px auto;
//     width: 100%;
//     max-width: 500px;
//     padding: 20px;
//     border: 1px solid #ddd;
//     border-radius: 8px;
//     background-color: #f9f9f9;
//   }

  .feedback-container {
    background-color: #f9f9f9;
    height: 50vh;
    max-height: 410px;
    overflow-y: scroll;
    padding-top: 10px;
    align-content: center;
    font-weight:lighter;
    width:100%;
  }
  
  .feedback-step h3 {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .feedback-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .like-button {
    background-color: #4caf50;
    color: white;
  }
  
  .dislike-button {
    background-color: #f44336;
    color: white;
  }
  
  .feedback-stars {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
  
  .star {
    font-size: 24px;
    cursor: pointer;
    color: #ccc;
    margin: 0 5px;
  }
  
  .star.selected {
    color: #ffb400;
  }
  
  textarea {
    width: 80%;
    min-height: 80px;
    margin: 10px 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none;
  }
  
  .submit-button,
  .next-button {
    padding: 10px 20px;
    background: linear-gradient(90deg, #48add9 0%, #42b649 100%) !important;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  