@import '../../../../../../../../scss/variables/colors';

.quick-button {
  background: none;
  border-radius: 15px;
  border: 2px solid $turqois-1;
  font-weight: bold;
  padding: 5px 10px;
  cursor: pointer;
  outline: 0;

  &:active {
    background: $turqois-1;
    color: $white;
  }
}
