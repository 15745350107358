/* Override widget header with brand gradient */
.rcw-conversation-container > .rcw-header {
    background: linear-gradient(90deg, #48add9 0%, #42b649 100%) !important;
  }
  
  
  /* Customize the send button */
  .rcw-send-button {
    background-color: #42b649 !important;
    color: white !important;
  }
  
/* Ensure the widget has a fixed width */
.rcw-widget-container {
    overflow: hidden !important; /* Hide overflow to prevent widget from growing */
    margin-right: 0px !important;
    padding:20px;
  }
  
  /* Ensure the input box takes up the full width but doesn't expand */
  .rcw-input-container {
    width: 100% !important; /* Make sure the input container takes full width */
    text-align: left !important;

  }
  
  /* Style the input box to prevent width growth and allow text wrapping */
  .rcw-input {
    box-sizing: border-box !important; /* Include padding/border in the element's width */
    word-wrap: break-word !important; /* Allow words to wrap */
    overflow-wrap: break-word !important; /* Ensure long words break and wrap */
    white-space: normal !important; /* Allow multi-line text */
    text-align: left !important;

  }
  
  /* Ensure message bubbles don't grow too wide */
  .rcw-message {
    max-width: 90% !important; /* Limit message bubble width */
    word-wrap: break-word !important; /* Wrap text inside the message bubble */
    overflow-wrap: break-word !important; /* Ensure long words wrap inside the bubble */
  }
  
  
  