@import url("https://use.typekit.net/zaf6tmd.css");

body {
  margin: 0;
  font-family: "Aileron", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: "Aileron", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

/* .test input {
  font-family: "Aileron", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.test {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: white;
  box-shadow: 2px 2px 40px rgb(0 0 0 / 7%);
} */
